import * as React from 'react';
import * as _ from 'lodash';
import { OfferingDomain } from '../../domain/offering-domain';
import { OfferingListWidgetDisplayOptions } from '../../display-options/offering-list-widget-display-options';
import { translate, TransProps } from 'react-i18next';
import { st, classes } from './MultiOfferings.st.css';
import { MultiOfferingsTitle } from './MultiOfferingsTitle/MultiOfferingsTitle';
import {
  CategoriesViewModel,
  IMultiOfferingsViewModel,
  MultiOfferingsViewModelFactory,
} from '../../domain/multi-offerings-view-model-factory';
import { OfferingCategoryDto } from '@wix/bookings-uou-domain';
import {
  RunningEnvironmentProps,
  withRunningEnvironmentContext,
} from '../context/running-environment-context';
import { OfferingList } from './OfferingList/OfferingList';
import {
  ExperimentsProps,
  withExperimentsContext,
} from '../../../Shared/context/experiments-context';
import Categories from './Categories/Categories';
import {
  CategoriesType,
  CategoryLayoutOptions,
} from '../../../Shared/appKeys/SettingsKeys';
import { TProp } from '../../../Shared/context/trans-prop';
import { SELECTED_CATEGORY_ID_DATA } from './constants';
import {
  BusinessInfo,
  ReservedLocationIds,
  ServiceLocation,
  ServiceLocationType,
} from '@wix/bookings-uou-types/dist/src';

interface MultiOfferingsProps
  extends TransProps,
    RunningEnvironmentProps,
    TProp,
    ExperimentsProps {
  offeringsDomain: OfferingDomain[];
  businessInfo: BusinessInfo;
  displayOptions: OfferingListWidgetDisplayOptions;
  categories: OfferingCategoryDto[];
  locations: ServiceLocation[];
  onCategoryChanged: Function;
  initialedCategories: string[];
  shouldListServicesFromOtherCategoriesForSeo?: boolean;
}

interface MultiOfferingsState {
  selectedCategoryIndex: number;
  multiOfferingsViewModel: IMultiOfferingsViewModel;
}

class MultiOfferingsComponent extends React.PureComponent<
  MultiOfferingsProps,
  MultiOfferingsState
> {
  constructor(props) {
    super(props);
    this.onCategorySelected = this.onCategorySelected.bind(this);
    const multiOfferingsViewModel = this.buildMultiOfferingsViewModel();
    this.state = {
      selectedCategoryIndex: this.getInitialedCategoryIndex(
        multiOfferingsViewModel.categoriesViewModel.categories,
      ),
      multiOfferingsViewModel,
    };
  }

  getInitialedCategoryIndex(categories): number {
    let initialedCategoryIndex = 0;
    const { initialedCategories } = this.props;
    if (initialedCategories.length) {
      const categoryIndex = categories.findIndex(
        (category) => category.id === initialedCategories[0],
      );
      initialedCategoryIndex =
        categoryIndex !== -1 ? categoryIndex : initialedCategoryIndex;
    }

    return initialedCategoryIndex;
  }

  buildMultiOfferingsViewModel() {
    const {
      t,
      displayOptions,
      categories,
      locations,
      runningEnvironment,
      experiments,
      offeringsDomain,
    } = this.props;
    const { isRTL, isDummyMode, isMobile } = runningEnvironment;
    return MultiOfferingsViewModelFactory.createMultiOfferingsViewModel(
      displayOptions.multiOfferingsDisplayOptions,
      offeringsDomain,
      categories,
      locations,
      t,
      isRTL, // todo - should be removed when yoshi will fix rtl!!!
      isMobile,
      isDummyMode,
      experiments,
    );
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(this.props, prevProps)) {
      this.setState({
        multiOfferingsViewModel: this.buildMultiOfferingsViewModel(),
      });
    }
  }

  filterOfferingsByCategory() {
    return this.getFilteredServiceBasedOnSelectedCategory(
      this.state.selectedCategoryIndex,
    );
  }

  onCategorySelected(selectedCategoryIndex: number) {
    this.setState({
      selectedCategoryIndex,
    });
    const selectedCategoryId = this.getCategoryId(selectedCategoryIndex);
    this.props.onCategoryChanged(selectedCategoryId);
  }

  private getOfferingsToDisplay() {
    const { isShowAllServicesCategoryEnabled } = this.props.experiments;
    return isShowAllServicesCategoryEnabled
      ? this.getOfferingsToDisplayWithAllServicesSupport()
      : this.getOfferingsToDisplayAllServicesInMobileOnly();
  }

  private getOfferingsToDisplayAllServicesInMobileOnly() {
    const { offeringsDomain } = this.props;
    const { multiOfferingsViewModel, selectedCategoryIndex } = this.state;
    const { layout, isVisible } = multiOfferingsViewModel.categoriesViewModel;
    return !isVisible ||
      (layout === CategoryLayoutOptions.DROPDOWN && selectedCategoryIndex === 0)
      ? offeringsDomain
      : this.filterOfferingsByCategory();
  }

  private getOfferingsToDisplayWithAllServicesSupport() {
    const { offeringsDomain } = this.props;
    const { multiOfferingsViewModel, selectedCategoryIndex } = this.state;
    const {
      isVisible,
      categories,
      categoriesType,
      locations,
    } = multiOfferingsViewModel.categoriesViewModel;
    const shouldShowAllServices =
      categoriesType === CategoriesType.SERVICE_LOCATIONS
        ? !locations[selectedCategoryIndex]?.id
        : !categories[selectedCategoryIndex]?.id;
    return !isVisible || shouldShowAllServices
      ? offeringsDomain
      : this.filterOfferingsByCategory();
  }

  private getCategoryId(categoryIndex) {
    const { multiOfferingsViewModel } = this.state;
    const { categoriesViewModel } = multiOfferingsViewModel;
    const { categories } = multiOfferingsViewModel.categoriesViewModel;
    const { experiments } = this.props;
    if (
      experiments.isUoUMultiLocationV1Enabled &&
      categoriesViewModel.categoriesType === CategoriesType.SERVICE_LOCATIONS
    ) {
      return categoriesViewModel.locations[categoryIndex]?.id;
    }
    return categories[categoryIndex]?.id;
  }

  getCategoryCategoriesTypeItems() {
    const {
      categories,
    } = this.state.multiOfferingsViewModel.categoriesViewModel;
    return categories.map((category) => ({
      title: category.name,
      id: category.id,
    }));
  }

  getCategoriesItemsBasedOnViewModel(categoriesViewModel: CategoriesViewModel) {
    const { experiments } = this.props;
    if (
      experiments.isUoUMultiLocationV1Enabled &&
      categoriesViewModel.categoriesType === CategoriesType.SERVICE_LOCATIONS
    ) {
      return categoriesViewModel.locations;
    }
    return this.getCategoryCategoriesTypeItems();
  }

  getFilteredServiceBasedOnSelectedCategory(index: number) {
    const { multiOfferingsViewModel } = this.state;
    const { offeringsDomain, experiments } = this.props;
    const { categories } = multiOfferingsViewModel.categoriesViewModel;
    const { categoriesViewModel } = multiOfferingsViewModel;
    if (
      experiments.isUoUMultiLocationV1Enabled &&
      categoriesViewModel.categoriesType === CategoriesType.SERVICE_LOCATIONS
    ) {
      const categoryItems = categoriesViewModel.locations;
      const selectedLocationId = categoryItems[index].id;
      if (selectedLocationId === ReservedLocationIds.OTHER_LOCATIONS) {
        return offeringsDomain.filter((offeringDomain) =>
          offeringDomain.locations.find(
            (location) =>
              location.type !== ServiceLocationType.OWNER_BUSINESS ||
              !location.businessLocation,
          ),
        );
      }
      return offeringsDomain.filter((offeringDomain) =>
        offeringDomain.locations.find(
          (location) => location?.businessLocation?.id === selectedLocationId,
        ),
      );
    }
    const categoryId = categories[index].id;
    return offeringsDomain.filter(
      (offeringDomain) => offeringDomain.categoryId === categoryId,
    );
  }

  render() {
    const {
      displayOptions,
      runningEnvironment,
      businessInfo,
      shouldListServicesFromOtherCategoriesForSeo,
      offeringsDomain,
    } = this.props;
    const { isMobile } = runningEnvironment;
    const { multiOfferingsViewModel } = this.state;
    const offeringsToDisplay = this.getOfferingsToDisplay();
    const categoriesItems = this.getCategoriesItemsBasedOnViewModel(
      multiOfferingsViewModel.categoriesViewModel,
    );
    return (
      <div
        data-hook="multi-offerings-container"
        {...{
          [SELECTED_CATEGORY_ID_DATA]: this.getCategoryId(
            this.state.selectedCategoryIndex,
          ),
        }}
        className={st(classes.root, {
          layout: multiOfferingsViewModel.layout,
          mobile: isMobile,
        })}
      >
        {multiOfferingsViewModel.title.isVisible && (
          <MultiOfferingsTitle
            htmlTag={multiOfferingsViewModel.title.htmlTag}
            alignment={multiOfferingsViewModel.title.alignment}
            data-hook="multi-offerings-title"
          >
            {multiOfferingsViewModel.title.text}
          </MultiOfferingsTitle>
        )}

        {multiOfferingsViewModel.categoriesViewModel.isVisible && (
          <Categories
            selectedCategoryIndex={this.state.selectedCategoryIndex}
            categoriesViewModel={multiOfferingsViewModel.categoriesViewModel}
            categoriesItems={categoriesItems}
            onCategorySelected={this.onCategorySelected}
          />
        )}
        <OfferingList
          categoryId={this.getCategoryId(this.state.selectedCategoryIndex)}
          displayOptions={displayOptions}
          offeringsDomain={offeringsToDisplay}
          businessInfo={businessInfo}
          multiOfferingsViewModel={multiOfferingsViewModel}
        />
        {shouldListServicesFromOtherCategoriesForSeo ? (
          <HiddenOfferingsForSeo
            offeringsToDisplay={offeringsToDisplay}
            allOfferings={offeringsDomain}
          />
        ) : null}
      </div>
    );
  }
}

const HiddenOfferingsForSeo = ({
  offeringsToDisplay,
  allOfferings,
}: {
  offeringsToDisplay: OfferingDomain[];
  allOfferings: OfferingDomain[];
}) => {
  const shouldRenderSeoOfferings =
    offeringsToDisplay.length !== allOfferings.length;
  const hiddenOfferings = React.useMemo<OfferingDomain[]>(() => {
    const offeringToDisplayMap = offeringsToDisplay.reduce((map, offering) => {
      map[offering.id] = true;
      return map;
    }, {});
    return allOfferings.filter(
      (offering) => !offeringToDisplayMap[offering.id],
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return shouldRenderSeoOfferings ? (
    <ul data-hook="hidden-offerings-for-seo" style={{ display: 'none' }}>
      {hiddenOfferings.map((offering) => (
        <li key={offering.id}>
          <a data-hook="hidden-offering-for-seo" href={offering.fullUrl}>
            {offering.name}
          </a>
        </li>
      ))}
    </ul>
  ) : null;
};

export const MultiOfferings = translate(null, { wait: true })(
  withExperimentsContext(
    withRunningEnvironmentContext(MultiOfferingsComponent),
  ),
);
