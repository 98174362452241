import * as React from 'react';
import { WidgetApp, WidgetAppViewMode } from './components/WidgetApp/WidgetApp';
import { withStyles } from '@wix/native-components-infra/dist/es/src/HOC/withStyles/withStyles';
import { overrideStyleProps } from '../Shared/appKeys/overrides';
import { withLeanStyles } from '@wix/native-components-infra';

export const ComponentNoSentry = withStyles(WidgetApp, {
  cssPath: ['widgetComponent.stylable.bundle.css'],
});

export const ComponentLeanCssNoSentry = withLeanStyles(WidgetApp, {
  cssPath: ['widgetComponent.stylable.bundle.css'],
});

export const ComponentNoSentryWithAppSettingData = (
  viewMode: WidgetAppViewMode,
) => (props) => {
  return (
    <ComponentNoSentry
      {...overrideStyleProps(props)}
      widgetViewMode={viewMode}
    />
  );
};

export const ComponentLeanCssNoSentryWithAppSettingData = (
  viewMode: WidgetAppViewMode,
) => (props) => {
  return (
    <ComponentLeanCssNoSentry
      {...overrideStyleProps(props)}
      widgetViewMode={viewMode}
    />
  );
};
